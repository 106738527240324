<template>
  <div class="vehType">
    <div class="vehTypeLeft">
      <el-tree
        :data="typeList"
        node-key="value"
        ref="tree"
        @node-click="treeClick"
        :expand-on-click-node="false"
        @check="handleCheckBox"
        highlight-current
        :render-content="renderContent"
        default-expand-all
      >
        <!-- :default-expanded-keys="[1]" -->
      </el-tree>
    </div>
    <div class="vehTypeRigth">
      <el-form :inline="true">
        <el-form-item>
          <el-input
            v-model="searchObj.typeName"
            placeholder="请输入车辆子类型"
            class="centerInput"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <button class="centerCzbtn" type="button" @click="getTable(searchPage)">
            搜索
          </button>
        </el-form-item>
        <el-form-item>
          <button class="centerBtn resetbtn" @click="reset" type="button">
            重置
          </button>
        </el-form-item>
      </el-form>
      <Table
        :data1="tabledata"
        :columns1="columns"
        :total="total"
        @getAjax="getTable"
        :loading="loading"
        :page="searchObj.page"
        :size="searchObj.size"
      />

      <!-- 添加 -->
      <el-dialog
        :title="isAdd ? '添加' : '编辑'"
        :visible.sync="isadd"
        width="30%"
        @close="addCarDia"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item
            :label="level == 1? '车辆类型' : '车辆子类型'"
            :label-width="formLabelWidth"
            prop="typeName"
          >
            <el-input
              v-model="ruleForm.typeName"
              autocomplete="off"
              class="centerCzInput cdInput"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item>

          <el-form-item
            label="是否可载人"
            :label-width="formLabelWidth"
            prop="isManned"
            v-if="addshow == true"
          >
            <el-select
              v-model="ruleForm.isManned"
              placeholder="请选择"
              class="centerCzSelInput"
            >
              <el-option
                v-for="item in isKzrList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button class="centerBtn" @click="carType" :loading="isLoading"
            >确 定</el-button
          >
          <button class="centerBtn resetbtn ml20" @click="isadd = false">
            取 消
          </button>
        </span>
      </el-dialog>

      <!-- 删除 -->
      <el-dialog title="删除" :visible.sync="delDia" width="30%">
        <span>确认删除吗</span>
        <span slot="footer" class="dialog-footer">
          <button class="centerBtn" @click="delConfirm">确 定</button>
          <button class="centerBtn resetbtn ml20" @click="delDia = false">
            取 消
          </button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {getTextWidth} from "@/until/helper.js"
import {
  _CarOperationType,
  _CarType,
  _AddCarType,
  _EdtCarType,
  _DeleteCarType,
  _FindAllPage,
  _AddParentCarType
} from "@/api/sass";
export default {
  components: {
    Table,
  },
  data() {
    return {
      level: null, // 当前等级
      loading: false,
      isLoading: false,
      delId: "",
      isadd: false,
      isAdd: false,
      delDia: false,
      total: 0,
      addshow: true,
      formLabelWidth: "100px",
      tabledata: [],
      isKzrList: [
        { value: 1, label: "是" },
        { value: 0, label: "否" },
      ],
      ruleForm: {
        parentId: "",
        typeName: "",
        level: "",
        isManned: "",
        id: "",
      },
      rules: {
        typeName: [
          {
            required: true,
            message: "请输入车辆类型",
            trigger: "blur",
          },
        ],
        isManned: [
          {
            required: true,
            message: "请选择是否可载人",
            trigger: "change",
          },
        ],
      },
      searchObj: {
        typeName: "",
        page: 1,
        size: 10,
        carTypeId: "",
      },
      searchPage:{
        limit: 10,
        page: 1
      },
      vehicleopTypeList: [],
      typeList: [
        {
          level: 0,
          typeName: "根菜单",
          children: [],
        },
      ],
      columns: [
        {
          label: "车辆类型",
          prop: "updateTime",
        },
        {
          label: "车辆子类型",
          prop: "typeName",
        },
        {
          label: "是否可载人",
          prop: "isManned",
          render: (h, params) => {
            return h("div", {}, params.row.isManned == 1 ? "是" : "否");
          },
        },
        {
          label: "添加人",
          prop: "userName",
          width: 150,
        },
        {
          label: "添加时间",
          prop: "createTime",
        },
      ],
      formdata: null,
    };
  },
  mounted() {
    this.vehicleoptype();
    this.getcarType();
    this.getTable();
  },
  methods: {
    //点击树形结构
    treeClick(data, node) {
      this.searchObj.carTypeId = data.id;
      this.getTable();
    },
    // 重置
    reset() {
      this.searchObj = {
        carTypeId: this.searchObj.carTypeId,
        typeName: "",
        page: 1,
        size: 10,
      };
      this.getTable();
    },
    // 确认删除、
    async delConfirm() {
      console.log(this.delId);
      let { success, data, message } = await _DeleteCarType({
        carTypeId: this.delId,
      });
      if (success) {
        this.$message.success("删除成功");
        this.delDia = false;
        this.getcarType();
      } else {
        this.$message.error(message);
      }
    },

    addCarDia() {
      this.ruleForm = {
        parentId: "",
        typeName: "",
        level: "",
        isManned: "",
      };
    },

    handleCheckBox() {},
    getAjax() {},
    renderContent(h, { node, data, store }) {
      let width = getTextWidth(data.typeName,null,14)
      let flag = width > 200 ? false : true 
      if (node.level == 2) {
        return (
          <div class="typeName-div">
            <el-tooltip disabled={flag} content={data.typeName} placement="top">
              <span class="typeName">{data.typeName}</span>
            </el-tooltip>
            <span
              class="treeSty"
              on-click={($event) => this.getAdd(node, data, store, $event)}
            >
              添加
            </span>
            <span
              class="treeSty"
              on-click={($event) => this.edtType(node, data, $event)}
            >
              编辑
            </span>
          </div>
        );
      }
      if (node.level == 1) {
        return (
          // 添加主类
          <div class="typeName-div">
            <el-tooltip disabled={flag} content={data.typeName} placement="top">
              <span class="typeName">{data.typeName}</span>
            </el-tooltip>
            <span
              class="treeSty"
              on-click={($event) => this.getAdd(node, data, store, $event)}
            >
              添加
            </span>
          </div>
        );
      } else {
        return (
          <div class="typeName-div">
            <el-tooltip disabled={flag} content={data.typeName} placement="top">
              <span class="typeName">{data.typeName}</span>
            </el-tooltip>
            <span
              class="treeSty"
              on-click={($event) => this.edtType(node, data, $event)}
            >
              编辑
            </span>
            <span
              class="treeSty"
              on-click={($event) => this.delType(node, data, $event)}
            >
              删除
            </span>
          </div>
        );
      }
    },
    delType(node, data, event) {
      event.stopPropagation();
      (this.delDia = true), (this.delId = data.id);
    },
    edtType(node, data, event) {
      console.log(node, data, event);
      this.level = node.level
      this.isadd = true;
      // this.addshow = true;
      // this.formdata = new FormData();
      // this.formdata.append("typeName", data.typeName);
      // this.formdata.append("isManned", data.isManned);
      // this.formdata.append("id", data.id);
      // this.formdata.append("parentId", data.parentId);
      // this.formdata.append("level", data.level);
      this.isAdd = false;
      this.ruleForm.typeName = data.typeName;
      this.ruleForm.isManned = data.isManned;
      this.ruleForm.id = data.id;
      this.ruleForm.parentId = data.parentId;
      console.log(data.parentId);
      this.ruleForm.level = data.level;
    },
    getAdd(node, data, store, event) {
      //阻止一下事件冒泡
      this.level = node.level
      event.stopPropagation();
      // if (node.level == 2) {
      //   this.addshow = true;
      // } else {
      //   this.addshow = false;
      // }
      this.isadd = true;
      this.isAdd = true;
      this.ruleForm.parentId = node.level == 1 ? 0 : data.id;
      this.ruleForm.level = node.level;
      this.$nextTick(()=>{
        this.$refs.ruleForm.clearValidate();
      })
    },
    async vehicleoptype() {
      let { success, data, message } = await _CarOperationType();
      if (success) {
        this.vehicleopTypeList = data.list;
      }
    },
    async getcarType() {
      let { success, data, message } = await _CarType();
      if (success) {
        this.typeList[0].children = data.list;
      } else {
        this.$message.error(message);
      }
    },
    carType() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.isAdd == false) {
            this.edtcarType();
          } else {
            this.addcarType();
          }
        }
      });
    },
    async addcarType() {
      this.isLoading = true;
      if(this.level == 1){
        try {
          let obj = {
            typeName: this.ruleForm.typeName,
            isManned: this.ruleForm.isManned
          }
          let {success,message } =await _AddParentCarType(obj)
          if(success){
            this.isadd = false;
            this.getcarType();
            this.isLoading = false;
          }else{
            this.$message.error(message);
            this.isLoading = false;
          }
        } catch (error) {
          this.isadd = false;
          this.isLoading = false;
        }
        return
      }
      _AddCarType(this.ruleForm).then((res) => {
        if (!res) {
          this.isLoading = false;
        }
        if (res.success) {
          this.isadd = false;
          this.getcarType();
          this.isLoading = false;
        } else {
          this.$message.error(res.message);
          this.isLoading = false;
        }
      });
    },
    async edtcarType() {
      this.isLoading = true;
      _EdtCarType(this.ruleForm).then((res) => {
        if (!res) {
          this.isLoading = false;
        }
        if (res.success) {
          this.isadd = false;
          this.getcarType();
          this.isLoading = false;
        } else {
          this.$message.error(res.message);
          this.isLoading = false;
        }
      });
    },

    // 分页查询表格数据
    async getTable(pagination) {
      if (pagination) {
        if (pagination.page) {
          this.searchObj.page = pagination.page;
          this.searchObj.size = pagination.limit;
        }
      }
      this.loading = true;
      let { success, data, message } = await _FindAllPage(this.searchObj);
      if (success) {
        this.tabledata = data.list;
        this.total = data.total;
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less">
.vehType {
  height: 100%;
  display: flex;
}
.vehTypeLeft {
  width: 30%;
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
}
.vehTypeLeft::-webkit-scrollbar,
.vehTypeRigth::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.vehTypeRigth {
  width: 70%;
  height: 100%;
  margin-left: 20px;
  overflow-y: auto;
}
.treeSty {
  color: #1683d9;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 550;
  z-index: 100;
}
.typeName-div{
  display: flex;
  align-items: center;
}
.typeName{
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>